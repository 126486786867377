var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parcel.seller)?_c('b-row',[_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Seller name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Seller name")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.seller.name),callback:function ($$v) {_vm.$set(_vm.parcel.seller, "name", $$v)},expression:"parcel.seller.name"}})]}}],null,false,3757328675)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('label',[_vm._v("Seller registry number")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled},model:{value:(_vm.parcel.seller.registry_number),callback:function ($$v) {_vm.$set(_vm.parcel.seller, "registry_number", $$v)},expression:"parcel.seller.registry_number"}})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Seller street number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Seller street number")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.seller.street_and_number),callback:function ($$v) {_vm.$set(_vm.parcel.seller, "street_and_number", $$v)},expression:"parcel.seller.street_and_number"}})]}}],null,false,2325811400)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Seller postal code","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Seller postal code")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.seller.postal_code),callback:function ($$v) {_vm.$set(_vm.parcel.seller, "postal_code", $$v)},expression:"parcel.seller.postal_code"}})]}}],null,false,2540249820)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Seller city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Seller city")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.seller.city),callback:function ($$v) {_vm.$set(_vm.parcel.seller, "city", $$v)},expression:"parcel.seller.city"}})]}}],null,false,645772451)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('label',[_vm._v("Seller region")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled},model:{value:(_vm.parcel.seller.region),callback:function ($$v) {_vm.$set(_vm.parcel.seller, "region", $$v)},expression:"parcel.seller.region"}})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Seller country","rules":"required|min:2|max:2|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Seller country")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.seller.country),callback:function ($$v) {_vm.$set(_vm.parcel.seller, "country", $$v)},expression:"parcel.seller.country"}})]}}],null,false,1677315619)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }