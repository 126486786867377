<template>
  <app-collapse>
    <app-collapse-item
      title="Notes"
      class="mb-1"
    >
      <app-collapse-item
        id="notes-container"
        title="Notes for internal use"
        :is-visible="true"
      >
        <validation-observer ref="noteValidation">
          <b-row v-if="canEdit">
            <b-col
              lg="6"
              md="6"
            >
              <label>Notes</label>
              <validation-provider
                #default="{ errors }"
                name="Notes"
                rules="required"
              >
                <b-form-textarea
                  v-model="input.note"
                  :state="errors.length > 0 ? false:null"
                  rows="9"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="canEdit">
            <b-col class="text-right">
              <b-button
                variant="primary"
                @click="validationForm()"
              >
                Add Note
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row
          v-if="notes && notes.length > 0"
          class="mt-2"
        >
          <b-col>
            <b-table
              :items="notes"
              responsive
              :fields="fields"
              stacked="sm"
              class="mb-0"
              show-empty
            >
              <template #cell(note)="data">
                <div
                  class="d-flex align-items-center"
                  style="white-space: pre"
                >
                  {{ data.item.note }}
                </div>
              </template>
              <template #cell(created_at)="data">
                <div class="d-flex align-items-center">
                  {{ data.item.created_at | formatDate }}
                </div>
              </template>
              <template #cell(actions)="data">
                <b-button-group>
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    @click="deleteItem(data.item.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="d-inline"
                    />
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse-item>

  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BButton, BButtonGroup, BCol, BFormTextarea, BRow, BTable } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleError from '@/views/components/errorHandler'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,
    BRow,
    BTable,
    BButton,
    BCol,
    BButtonGroup,
    AppCollapseItem,
    AppCollapse,
  },
  props: ['parcelId', 'companyId'],
  data() {
    return {
      input: {
        note: '',
      },
      notes: [],
      fields: [
        { key: 'note', label: 'Note', sortable: true },
        { key: 'user.name', label: 'User', sortable: true },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true,
          thClass: 'd-none d-sm-table-cell',
          tdClass: 'd-none d-sm-table-cell',
        },
        { key: 'actions', label: '', sortable: false },
      ],
    }
  },
  created() {
    this.fetchNotes()
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
  },
  methods: {
    validationForm() {
      this.$refs.noteValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.submitNote()
      })
    },
    submitNote() {
      if (this.input.note) {
        this.$http.post(`/v1/companies/${this.companyId}/parcels/${this.parcelId}/notes`, this.input)
          .then(response => {
            if (response.data.note) {
              this.notes.push(response.data.note)
            }
            this.input.note = ''
          })
      }
    },
    deleteItem(id) {
      this.$http.delete(`/v1/companies/${this.companyId}/parcels/${this.parcelId}/notes/${id}`)
        .then(() => {
          this.notes = this.notes.filter(note => note.id !== id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Note removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    fetchNotes() {
      this.$http.get(`/v1/companies/${this.companyId}/parcels/${this.parcelId}/notes`)
        .then(response => {
          this.notes = response.data.data
        })
    },
  },
}
</script>
