var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parcel.buyer)?_c('b-row',[_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Buyer name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Buyer name")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.buyer.name),callback:function ($$v) {_vm.$set(_vm.parcel.buyer, "name", $$v)},expression:"parcel.buyer.name"}})]}}],null,false,2233834659)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Buyer registry number","rules":_vm.parcel.declaration_type === 'H1' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Buyer registry number")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.buyer.registry_number),callback:function ($$v) {_vm.$set(_vm.parcel.buyer, "registry_number", $$v)},expression:"parcel.buyer.registry_number"}})]}}],null,false,2804020732)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Buyer street number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Buyer street number")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.buyer.street_and_number),callback:function ($$v) {_vm.$set(_vm.parcel.buyer, "street_and_number", $$v)},expression:"parcel.buyer.street_and_number"}})]}}],null,false,2539064296)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Buyer postal code","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Buyer postal code")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.buyer.postal_code),callback:function ($$v) {_vm.$set(_vm.parcel.buyer, "postal_code", $$v)},expression:"parcel.buyer.postal_code"}})]}}],null,false,1995472316)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Buyer city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Buyer city")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.buyer.city),callback:function ($$v) {_vm.$set(_vm.parcel.buyer, "city", $$v)},expression:"parcel.buyer.city"}})]}}],null,false,1529687843)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('label',[_vm._v("Buyer region")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled},model:{value:(_vm.parcel.buyer.region),callback:function ($$v) {_vm.$set(_vm.parcel.buyer, "region", $$v)},expression:"parcel.buyer.region"}})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Buyer country","rules":"required|min:2|max:2|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Buyer country")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.buyer.country),callback:function ($$v) {_vm.$set(_vm.parcel.buyer, "country", $$v)},expression:"parcel.buyer.country"}})]}}],null,false,3105928099)})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('label',[_vm._v("Buyer email")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled},model:{value:(_vm.parcel.buyer.email),callback:function ($$v) {_vm.$set(_vm.parcel.buyer, "email", $$v)},expression:"parcel.buyer.email"}})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('label',[_vm._v("Buyer phone")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled},model:{value:(_vm.parcel.buyer.phone),callback:function ($$v) {_vm.$set(_vm.parcel.buyer, "phone", $$v)},expression:"parcel.buyer.phone"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }