<template>
  <!-- Seller data -->
  <b-row>
    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Invoice reference number"
        rules="max:35"
      >
        <label>Invoice reference number</label>
        <b-form-input
          v-model="parcel.invoice_reference_number"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Delivery terms country"
        rules="required|min:2|max:2|alpha"
      >
        <label>Delivery terms country</label>
        <b-form-input
          v-model="parcel.delivery_terms_country"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Delivery terms location name"
        rules="required|max:35"
      >
        <label>Delivery terms location name</label>
        <b-form-input
          v-model="parcel.delivery_terms_location_name"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Delivery terms code"
        rules="required|max:3"
      >
        <label>Delivery terms code</label>
        <b-form-input
          v-model="parcel.delivery_terms_code"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
}
</script>
