var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"invalidate-declaration-modal",staticStyle:{"top":"10px"},attrs:{"id":"invalidate-declaration-modal","title":"Invalidate declaration"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var close = ref.close;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('b-button',{on:{"click":close}},[_vm._v(" Close ")])],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"danger","disabled":_vm.loading},on:{"click":_vm.validationForm}},[(!_vm.loading)?_c('span',[_vm._v("Request invalidation")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)],1)],1)]}}])},[_c('validation-observer',{ref:"invalidateDeclarationForm"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"code-selector"},[_c('b-form-group',{attrs:{"label":"Code","label-for":"code"}},[_c('validation-provider',{attrs:{"name":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.codeOptions,"clearable":false,"state":errors.length > 0 ? false : null,"input-id":"code","reduce":function (option) { return option.value; },"attach":""},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Invalidation reason","label-for":"reason"}},[_c('validation-provider',{attrs:{"name":"Invalidation reason","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"mr-0 ml-0",attrs:{"id":"reason","name":"reason","state":errors.length > 0 ? false : null},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.isPostClearanceLV)?_c('b-form-group',{attrs:{"label":"Phone number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"min:5|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"id":"phone","name":"phone","state":errors.length > 0 ? false : null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2997499254)})],1):_vm._e(),(_vm.isPostClearanceLV)?_c('b-form-group',{attrs:{"label":"E-mail","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"email|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"id":"email","name":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1133944038)})],1):_vm._e(),(_vm.isPostClearanceLV)?_c('b-form-group',{attrs:{"label":"Bank account number","label-for":"bank_account"}},[_c('validation-provider',{attrs:{"name":"Bank account number","rules":"alpha_num|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"id":"bank_account","name":"bank_account","state":errors.length > 0 ? false : null},model:{value:(_vm.form.bank_account),callback:function ($$v) {_vm.$set(_vm.form, "bank_account", $$v)},expression:"form.bank_account"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3608827314)})],1):_vm._e(),(_vm.isPostClearanceLV)?_c('b-form-file',{ref:"fileHandle",staticClass:"b-custom-control-xl",attrs:{"id":"files","placeholder":"Select a file","drop-placeholder":"Drop file here...","multiple":true},on:{"input":function($event){return _vm.submitFile()}},model:{value:(_vm.uploadFile),callback:function ($$v) {_vm.uploadFile=$$v},expression:"uploadFile"}}):_vm._e()],1)],1),(_vm.files && _vm.files.length > 0)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('label',[_vm._v("Files")]),_c('br'),_c('b-table',{staticClass:"files-table",attrs:{"fields":[{ key: 'name', label: 'Name' }, { key: 'actions', label: '' }],"items":_vm.files,"thead-class":"d-none","show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-link',{attrs:{"href":(_vm.fileBaseUrl + "/files/" + (data.item.id))}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"disabled":data.item.declarations > 0,"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.deleteFile(data.item.id)}}},[(_vm.deletingId !== data.item.id)?_c('feather-icon',{staticClass:"d-inline",attrs:{"icon":"TrashIcon"}}):_c('b-spinner',{attrs:{"small":""}})],1)]}}],null,false,279039934)})],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_vm._v(" This request initiates the cancellation of a customs declaration. The customs authority will review the submission and make the final determination on the cancellation. ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }