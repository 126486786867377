var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Invoice reference number","rules":"max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Invoice reference number")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.invoice_reference_number),callback:function ($$v) {_vm.$set(_vm.parcel, "invoice_reference_number", $$v)},expression:"parcel.invoice_reference_number"}})]}}])})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Delivery terms country","rules":"required|min:2|max:2|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Delivery terms country")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.delivery_terms_country),callback:function ($$v) {_vm.$set(_vm.parcel, "delivery_terms_country", $$v)},expression:"parcel.delivery_terms_country"}})]}}])})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Delivery terms location name","rules":"required|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Delivery terms location name")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.delivery_terms_location_name),callback:function ($$v) {_vm.$set(_vm.parcel, "delivery_terms_location_name", $$v)},expression:"parcel.delivery_terms_location_name"}})]}}])})],1),_c('b-col',{attrs:{"lg":"3","md":"4"}},[_c('validation-provider',{attrs:{"name":"Delivery terms code","rules":"required|max:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Delivery terms code")]),_c('b-form-input',{staticClass:"mr-0 ml-0",attrs:{"disabled":_vm.disabled,"state":errors.length > 0 ? false:null},model:{value:(_vm.parcel.delivery_terms_code),callback:function ($$v) {_vm.$set(_vm.parcel, "delivery_terms_code", $$v)},expression:"parcel.delivery_terms_code"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }