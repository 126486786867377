<template>
  <b-button
    v-b-modal.edit-parcel-items
    type="button"
    class="parcel_view"
    variant="primary"
    size="sm"
    @click="viewParcel"
  >
    <b-icon-eye />
  </b-button>
</template>

<script>
import {
  BButton, BIconEye,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BIconEye,
  },
  props: ['eventHub', 'declaration', 'parcel'],
  methods: {
    viewParcel() {
      this.eventHub.$emit('set-view-parcel', this.declaration, this.parcel)
    },
  },
}
</script>

<style scoped>
  svg {
    font-size: 1em;
  }
</style>
