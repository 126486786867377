<template>
  <b-card
    v-if="shipment && declaration"
    title="Customs declaration"
  >
    <b-row>
      <b-col
        v-if="declaration.previous_document_category"
        lg="3"
        md="4"
      >
        <label>Previous document category</label>
        <v-select
          v-model="declaration.previous_document_category"
          :options="previous_document_categories"
          :clearable="false"
          :disabled="disabled"
          :reduce="item => item.value"
          label="text"
          input-id="previous-document-category"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Previous document type</label>
        <v-select
          id="previous-document-type"
          v-model="declaration.previous_document_type"
          :options="previousDocumentTypes"
          :clearable="false"
          :disabled="disabled"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Previous document reference</label>
        <b-form-input
          id="previous-document-reference"
          v-model="declaration.previous_document_reference"
          :disabled="disabled"
          class="mr-0 ml-0"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <b-form-group
          label="Choose warehouse"
          label-for="vue-select"
        >
          <validation-provider
            #default="{ errors }"
            name="Warehouse"
            :rules="'required'"
          >
            <b-form-select
              id="warehouse"
              v-model="declaration.warehouse_id"
              :options="warehouses"
              :state="errors.length > 0 ? false : null"
              :disabled="disabled"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="declaration.declaration_type === 'H1'"
        lg="3"
        md="4"
      >
        <b-form-group
          label="Inland mode of transport"
          label-for="inland_mode_of_transport"
        >
          <validation-provider
            #default="{ errors }"
            name="Inland mode of transport"
            :rules="'required'"
          >
            <b-form-select
              id="inland_mode_of_transport"
              v-model="declaration.inland_mode_of_transport"
              :options="transport_types"
              :state="errors.length > 0 ? false : null"
              :disabled="disabled"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="declaration.declaration_type === 'H1'"
        lg="3"
        md="4"
      >
        <b-form-group
          label="Mode of transport at border"
          label-for="mode_of_transport_at_border"
        >
          <validation-provider
            #default="{ errors }"
            name="Mode of transport at border"
            :rules="'required'"
          >
            <b-form-select
              id="mode_of_transport_at_border"
              v-model="declaration.mode_of_transport_at_border"
              :options="transport_types"
              :state="errors.length > 0 ? false : null"
              :disabled="disabled"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="declaration.status_info && declaration.status_info['errors']">
      <b-col>
        <label>Declaration status</label>
        <ul class="pl-2">
          <li
            v-for="(error, index) in declaration.status_info['errors']"
            :key="index"
          >
            {{ error.message || 'error message is undefined' }}
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BCard, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import declarationDataTypeCache from '@/views/shipments/declarations/components/classes/declarationDataTypeCache'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BFormSelect,
    ValidationProvider,
    BFormGroup,
    BCard,
    vSelect,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    shipment: {
      type: Object,
      required: true,
    },
    declaration: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      previousDocumentTypes: [],
      previous_document_categories: [
        { value: 'X', text: 'Summary Declaration' },
        { value: 'Y', text: 'Initial Declaration' },
        { value: 'Z', text: 'Previous Document' },
      ],
      transport_types: this.$classifiers().getOptions('transport_types'),
      warehouses: [{ value: '', text: '----select----' }],
    }
  },
  created() {
    this.fetchPreviousDocTypes()
    this.$http.get(`/v1/companies/${this.shipment.company_id}/warehouses`)
      .then(response => {
        const warehouses = response.data.data

        this.warehouses = this.warehouses.concat(warehouses.map(warehouse => ({ value: warehouse.id, text: warehouse.name })))
      })
  },
  methods: {
    fetchPreviousDocTypes() {
      if (this.shipment) {
        const cachedData = declarationDataTypeCache().getCachedDataTypes(this.shipment.entry_country)
        if (cachedData === null) {
          declarationDataTypeCache().getDataTypes(this.shipment.entry_country, this)
            .then(response => {
              response.data.data.forEach(type => {
                this.previousDocumentTypes.push(type?.type)
              })
              declarationDataTypeCache().cacheDataTypes(this.previousDocumentTypes)
            })
        } else {
          this.previousDocumentTypes = cachedData
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
