import axios from '@/libs/axios'
import handleError from '@/views/components/errorHandler'
import store from '@/store'

export default function declarationDataTypeCache() {
  const clearCache = () => {
    store.state.app.declarationDataTypeCache = {}
  }

  const cacheDataTypes = (country, data) => {
    if (!store.state.app.declarationDataTypeCache) {
      store.state.app.declarationDataTypeCache = {}
    }
    store.state.app.declarationDataTypeCache[country] = data
  }

  const getCachedDataTypes = country => {
    if (store.state.app.declarationDataTypeCache && store.state.app.declarationDataTypeCache[country]) {
      return store.state.app.declarationDataTypeCache[country]
    }

    return null
  }

  const getDataTypes = entryCountry => {
    const ax = axios.get(`/v1/customs/previous-document-types/${entryCountry}`)
      .catch(error => {
        handleError(error, this.$toast)
      })
    return ax
  }

  return {
    getDataTypes,
    clearCache,
    cacheDataTypes,
    getCachedDataTypes,
  }
}
