<template>
  <app-collapse>
    <app-collapse-item
      title="Uploads"
      class="mb-1"
    >
      <app-collapse-item
        id="document-container"
        title="Uploads for internal use"
        :is-visible="true"
      >
        <b-row v-if="canEdit">
          <b-col
            lg="6"
            md="6"
          >
            <b-form-file
              v-if="!upload.isUploading"
              v-model="uploadFile"
              id="attachment-upload-btn"
              :ref="`fileHandle-${parcelId}`"
              placeholder="Select a file"
              drop-placeholder="Drop file here..."
              :multiple="true"
              class="b-custom-control-xl"
              @input="submitFile()"
            />
            <b-progress
              v-if="upload.isUploading"
              ref="fileProgress"
              :value="upload.uploadProgress"
              max="100"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="input.document_attachments && input.document_attachments.length > 0"
          class="mt-2"
        >
          <b-col>
            <b-table
              :items="input.document_attachments"
              responsive
              :fields="fields"
              stacked="sm"
              class="mb-0"
              show-empty
            >
              <template #cell(name)="data">
                <div
                  class="d-flex align-items-center"
                  style="white-space: pre"
                >
                  <b-link :href="`${fileBaseUrl}/${data.item.id}`">
                    {{ data.item.name }}
                  </b-link>
                </div>
              </template>
              <template #cell(created_at)="data">
                <div class="d-flex align-items-center">
                  {{ data.item.created_at | formatDate }}
                </div>
              </template>
              <template #cell(actions)="data">
                <b-button-group>
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    @click="deleteItem(data.item.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="d-inline"
                    />
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse-item>

  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BButton, BButtonGroup, BCol, BFormFile, BLink, BProgress, BRow, BTable } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleError from '@/views/components/errorHandler'

export default {
  components: {
    BLink,
    BRow,
    BButton,
    BTable,
    BCol,
    BButtonGroup,
    BFormFile,
    BProgress,
    AppCollapseItem,
    AppCollapse,
  },
  props: ['parcelId', 'companyId'],
  data() {
    return {
      uploadFile: null,
      input: {
        document_attachments: [],
      },
      upload: {
        isUploading: false,
        uploadProgress: 0,
      },
      fileBaseUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.companyId}/parcels/${this.parcelId}/attachments`,
      fields: [
        { key: 'name', label: 'File', sortable: true },
        { key: 'user.name', label: 'User', sortable: true },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true,
          thClass: 'd-none d-sm-table-cell',
          tdClass: 'd-none d-sm-table-cell',
        },
        { key: 'actions', label: '', sortable: false },
      ],
    }
  },
  created() {
    this.fetchAttachments()
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
  },
  methods: {
    fetchAttachments() {
      this.$http.get(`/v1/companies/${this.companyId}/parcels/${this.parcelId}/attachments`)
        .then(response => {
          this.input.document_attachments = response.data.data
        })
    },
    deleteItem(id) {
      this.$http.delete(`/v1/companies/${this.companyId}/parcels/${this.parcelId}/attachments/${id}`)
        .then(() => {
          this.input.document_attachments = this.input.document_attachments.filter(item => item.id !== id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    submitFile() {
      const formData = new FormData()
      this.uploadFile.forEach((file, i) => {
        formData.append(`files[${i}]`, file)
      })

      this.upload.isUploading = true
      this.upload.uploadProgress = 0
      this.$http.post(`/v1/companies/${this.companyId}/parcels/${this.parcelId}/attachments`,
        formData,
        {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.upload.uploadProgress = percentCompleted

            return percentCompleted
          }.bind(this),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.upload.isUploading = false
          if (response.data.documentAttachments && response.data.documentAttachments.length > 0) {
            response.data.documentAttachments.forEach(doc => {
              this.input.document_attachments.push(doc)
            })
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File successfully uploaded',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.upload.isUploading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to upload file',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
