<template>
  <b-col class="px-0">
    <parcel-event-history-modal
      v-if="selectedParcel"
      :parcel="selectedParcel"
    />
    <edit-parcel-items
      v-if="selectedParcel"
      id="edit-parcel-items"
      :parcel="selectedParcel"
      :event-hub="eventHub"
      :declaration="selectedDeclaration"
      :shipment="selectedParcel ? selectedParcel.shipment : null"
      readonly
    />
    <b-col class="text-right px-0">
      <b-button
        variant="outline-success"
        class="m-1"
        :href="downloadWarehouseExcel"
      >
        Download Excel for warehouse
      </b-button>
      <b-button
        variant="outline-success"
        class="m-1"
        :href="downloadExcel"
      >
        Download Excel
      </b-button>
    </b-col>
    <b-card
      no-body
      class="parcels-table"
    >
      <b-card-actions
        action-refresh
        @refresh="fetchData()"
      >
        <div class="overflow-x-scroll">
          <b-table
            :items="computedTableData"
            responsive
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy="loading"
            stacked="sm"
            class="mb-0"
            show-empty
            :tbody-tr-class="rowClass"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template
              v-if="showLogoColumn"
              #cell(logo)="data"
            >
              <CompanyThumb
                :logo="data.item.company.logo"
                :name="data.item.company.name"
              />
            </template>

            <template #cell(low_value_declaration.status)="data">
              <div
                v-if="data.item.low_value_declaration"
                class="d-flex align-items-center"
              >
                <b-badge
                  v-if="getStatusInfo(data.item.low_value_declaration.status, 'variant')"
                  pill
                  :variant="getStatusInfo(data.item.low_value_declaration.status, 'variant')"
                >
                  {{ getStatusInfo(data.item.low_value_declaration.status, 'label') }}
                </b-badge>
              </div>
            </template>

            <template #cell(shipment.document_number)="data">
              <router-link :to="{ name: 'shipment', params: { shipmentId: data.item.shipment.id } }">
                {{ data.item.shipment.document_number }}
              </router-link>
            </template>

            <template #cell(low_value_declaration.created_at)="data">
              <div
                v-if="data.item.low_value_declaration"
                class="d-flex align-items-center"
              >
                {{ data.item.low_value_declaration.created_at | formatDate }}
              </div>
            </template>

            <template #cell(low_value_declaration.updated_at)="data">
              <div
                v-if="data.item.low_value_declaration"
                class="d-flex align-items-center"
              >
                {{ data.item.low_value_declaration.updated_at | formatDate }}
              </div>
            </template>

            <!-- Column: Actions -->
            <template
              #cell(actions)="data"
              class="px-1"
            >
              <div class="d-flex align-items-center">
                <b-button-group>
                  <view-parcel-button
                    :declaration="data.item.low_value_declaration"
                    :parcel="data.item"
                    :event-hub="eventHub"
                  />
                  <download-pdf-button
                    :declaration="data.item.low_value_declaration"
                    :parcel="data.item"
                    :shipment="data.item.shipment"
                  />
                  <b-button
                    v-b-modal.parcel-event-history
                    type="button"
                    class="showEventsHistoryModal"
                    variant="primary"
                    size="sm"
                    @click="selectedParcel = data.item"
                  >
                    <b-icon-clock-history />
                  </b-button>
                </b-button-group>
                <b-icon-archive
                  class="ml-1 cursor-pointer text-secondary"
                  v-if="data.item.is_archived"
                  v-b-tooltip.hover.top="'Buyer personal data is removed'"
                />
              </div>
            </template>
          </b-table>
        </div>
        <pagination
          :event-hub="eventHub"
          :per-page="perPage"
        />
      </b-card-actions>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BTable, BBadge, BButton, BCol, VBTooltip, BButtonGroup, BSpinner, BIconArchive, BIconClockHistory,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import handleError from '@/views/components/errorHandler'
import Pagination from '../../layouts/components/Pagination/Pagination.vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import DownloadPdfButton from '@/views/shipments/declarations/components/DownloadPdfButton.vue'
import CompanyThumb from '@/views/components/CompanyThumb.vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import ParcelEventHistoryModal from '@/views/parcels/ParcelEventHistoryModal.vue'

export default {
  components: {
    ParcelEventHistoryModal,
    BIconArchive,
    EditParcelItems,
    BSpinner,
    CompanyThumb,
    BCard,
    BCardActions,
    BTable,
    BBadge,
    BCol,
    BButton,
    BButtonGroup,
    Pagination,
    ViewParcelButton,
    DownloadPdfButton,
    BIconClockHistory,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['tableData', 'eventHub', 'statuses', 'showLogoColumn'],
  data() {
    return {
      page: 1,
      perPage: 30,
      tableDataStore: [],
      loading: false,
      parcels: [],
      sortBy: null,
      isSortDirDesc: null,
      filters: {},
      fields: [
        { key: 'actions', label: 'Actions' },
        ...[this.showLogoColumn ? { key: 'logo', label: 'Company', sortable: false } : null],
        { key: 'tracking_number', label: 'Tracking number', sortable: false },
        { key: 'low_value_declaration.status', label: 'Status', sortable: false },
        { key: 'container_code', label: 'Container', sortable: false },
        { key: 'shipment.document_number', label: 'Document number', sortable: false },
        { key: 'low_value_declaration.lrn', label: 'LRN', sortable: false },
        { key: 'low_value_declaration.mrn', label: 'MRN', sortable: false },
        {
          key: 'low_value_declaration.created_at',
          label: 'Declaration time',
          sortable: true,
          thClass: 'd-none d-sm-table-cell',
          tdClass: 'd-none d-sm-table-cell',
        },
        {
          key: 'low_value_declaration.updated_at',
          label: 'Last event',
          sortable: true,
          thClass: 'd-none d-sm-table-cell',
          tdClass: 'd-none d-sm-table-cell',
        },
      ],
      selectedDeclaration: null,
      selectedParcel: null,
      indexUrl: `/v1/companies/${this.$activeCompany().data.company.id}/parcels`,
      downloadWarehouseExcelUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.$activeCompany().data.company.id}/parcels/warehouse-excel`,
      downloadExcelUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.$activeCompany().data.company.id}/parcels/excel`,
      additionalStatuses: [
        { label: 'Needs attention', value: 'unpaid', variant: 'warning' },
        { label: 'Needs attention', value: 'errored', variant: 'warning' },
      ],
    }
  },
  computed: {
    computedTableData() {
      return this.tableDataStore
    },
    downloadWarehouseExcel() {
      return this.searchQuery(this.downloadWarehouseExcelUrl)
    },
    downloadExcel() {
      return this.searchQuery(this.downloadExcelUrl)
    },
  },
  watch: {
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  mounted() {
    this.eventHub.$on('updateData', data => {
      this.tableDataStore = Object.values(data)
    })
    this.eventHub.$on('updateFilters', data => {
      this.page = 1
      this.filters = data
      this.fetchData()
    })
    this.tableDataStore = this.$props.tableData
    this.eventHub.$on('pageChange', newPage => {
      this.page = newPage
      this.fetchData()
    })
    this.eventHub.$on('set-view-parcel', this.viewParcel)
  },
  methods: {
    rowClass(item) {
      return item && item.is_archived ? 'archived-row muted' : ''
    },
    searchQuery(url) {
      // Apply page
      url += `?page=${this.$data.page}`
      url += `&per_page=${this.$data.perPage}`

      // Apply filters
      if (Object.keys(this.$data.filters).length > 0) {
        Object.keys(this.$data.filters).forEach(key => {
          if (this.$data.filters[key] !== null) {
            url += `&${key}=${this.$data.filters[key]}`
          }
        })
      }

      // Apply sorting
      url += `&sortBy=${this.$data.sortBy}`
      url += `&sortDesc=${this.$data.isSortDirDesc}`

      return url
    },
    viewParcel(declaration, parcel) {
      this.selectedDeclaration = declaration
      this.selectedParcel = parcel
    },
    fetchData() {
      this.loading = true
      // Request
      this.$http.get(this.searchQuery(this.indexUrl))
        .then(response => {
          this.$data.parcels = response.data.data
          this.$props.eventHub.$emit('updateData', response.data.data)
          this.$props.eventHub.$emit('updateMeta', response.data.meta)
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getStatusInfo(statusCode, field) {
      let filteredStatus = this.statuses.find(status => status.value === statusCode)

      if (!filteredStatus) {
        filteredStatus = this.additionalStatuses.find(status => status.value === statusCode)
      }

      return filteredStatus ? filteredStatus[field] : null
    },
  },
}
</script>

<style>
  .parcels-table .table td[data-label="Actions"] {
    padding: .72rem !important;
  }
  .archived-row {
    background-color: #fafafa;
    color: #999;
    opacity: 0.8;
  }
</style>
