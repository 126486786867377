<template>
  <!-- Buyer data -->
  <b-row v-if="parcel.buyer">
    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer name"
        rules="required"
      >
        <label>Buyer name</label>
        <b-form-input
          v-model="parcel.buyer.name"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer registry number"
        :rules="parcel.declaration_type === 'H1' ? 'required' : ''"
      >
        <label>Buyer registry number</label>
        <b-form-input
          v-model="parcel.buyer.registry_number"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer street number"
        rules="required"
      >
        <label>Buyer street number</label>
        <b-form-input
          v-model="parcel.buyer.street_and_number"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer postal code"
        rules="required|max:20"
      >
        <label>Buyer postal code</label>
        <b-form-input
          v-model="parcel.buyer.postal_code"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer city"
        rules="required"
      >
        <label>Buyer city</label>
        <b-form-input
          v-model="parcel.buyer.city"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer region</label>
      <b-form-input
        v-model="parcel.buyer.region"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <validation-provider
        #default="{ errors }"
        name="Buyer country"
        rules="required|min:2|max:2|alpha"
      >
        <label>Buyer country</label>
        <b-form-input
          v-model="parcel.buyer.country"
          :disabled="disabled"
          class="mr-0 ml-0"
          :state="errors.length > 0 ? false:null"
        />
      </validation-provider>
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer email</label>
      <b-form-input
        v-model="parcel.buyer.email"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer phone</label>
      <b-form-input
        v-model="parcel.buyer.phone"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      required,
    }
  },
}
</script>
