<template>
  <b-button
    v-if="pdfFiles.length > 0"
    v-b-tooltip.hover.top="pdfFiles.length > 1 ? 'Download PDF Files' : pdfFiles[0]['name']"
    variant="primary"
    size="sm"
    @click="toggleDropdown"
  >
    <b-icon-file-earmark-arrow-down />
    <ul
      v-if="pdfFiles.length > 1"
      v-show="dropdownVisible"
      class="navbar-nav"
    >
      <li
        v-for="(pdf, index) in pdfFiles"
        :key="index"
        class="nav-item"
        @click="downloadPdf(pdf)"
      >
        {{ pdf.name }}
      </li>
    </ul>
  </b-button>
</template>

<script>
import {
  BButton, VBTooltip, BIconFileEarmarkArrowDown,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BIconFileEarmarkArrowDown,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    declaration: {
      type: Object,
      default: () => {},
    },
    shipment: {
      type: Object,
      default: () => {},
    },
    parcel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const companyId = this.parcel.shipment?.company_id
    const files = []
    if (this.declaration) {
      files.push({
        name: 'H7 declaration excerpt',
        url: `${process.env.VUE_APP_API_URL}/v1/companies/${companyId}/shipments/${this.parcel.shipment_id}/parcels/${this.parcel.id}/pdf`,
      })
    }
    if (this.shipment?.company?.is_customs_invoice_allowed) {
      files.push({
        name: 'Customs invoice',
        url: `${process.env.VUE_APP_API_URL}/v1/companies/${companyId}/shipments/${this.parcel.shipment_id}/parcels/${this.parcel.id}/invoice`,
      })
    }
    return {
      dropdownVisible: false,
      pdfFiles: files,
    }
  },
  methods: {
    toggleDropdown() {
      if (this.pdfFiles.length > 1) {
        this.dropdownVisible = !this.dropdownVisible
      } else {
        this.downloadPdf(this.pdfFiles[0])
      }
    },
    downloadPdf(pdf) {
      const link = document.createElement('a')
      link.href = pdf.url
      link.download = pdf.name
      link.click()
      this.dropdownVisible = false
    },
  },
}
</script>

<style scoped>
  svg {
    font-size: 1em;
  }
  .navbar-nav {
    position: absolute;
    text-align: left;
    background-color: white;
    color: #6e6b7b;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .navbar-nav li {
    padding: 8px 16px;
    cursor: pointer;
    white-space: nowrap;
  }
  .navbar-nav li:hover {
    background-color: #f1f1f1;
  }
</style>
