<template>
  <b-modal
    id="clear-manually-modal"
    v-model="show"
    title="Clear manually"
    style="top: 10px"
    @hide="hidingActions"
  >

    <validation-observer ref="clearManuallyRules">
      <b-row>
        <b-col>
          Are you sure you want to continue, parcel will be marked as cleared?
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-form-group
            label="MRN"
            label-for="mrn"
          >
            <validation-provider
              #default="{ errors }"
              name="MRN"
              rules="required|length:18"
            >
              <b-form-input
                id="mrn"
                v-model="mrn"
                name="mrn"
                :disabled="Boolean(declaration.mrn)"
                :state="errors.length > 0 ? false : null"
                class="mr-0 ml-0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>

    <template #modal-footer="{ close }">

      <b-container fluid>
        <b-row>
          <b-col>
            <b-button
              variant="danger"
              @click="close"
            >
              Close
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              variant="primary"
              @click="validationForm"
            >
              OK
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>
<script>

import {
  BButton,
  BCol,
  BContainer,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BFormInput,
    BCol,
    BRow,
    BButton,
    BContainer,
    BModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    declaration: {
      type: Object,
      default: () => {},
    },
    event: {
      type: Object,
      required: true,
    },
    shipmentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      mrn: this.declaration?.mrn,
      required,
    }
  },
  methods: {
    hidingActions() {
      this.$emit('close')
    },
    validationForm() {
      if (this.declaration.mrn) {
        this.manualClear()
        return
      }
      this.$refs.clearManuallyRules.validate().then(success => {
        if (!success) {
          return
        }
        this.manualClear()
      })
    },
    manualClear() {
      const input = {
        mrn: this.mrn,
      }
      this.$http.put(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/${this.declaration.id}/clear-manually`, input)
        .then(() => {
          this.event.$emit('renewDeclarationList')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Status changed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$emit('saved')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Status change failed',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
