<template>
  <app-collapse-item
    :id="'communication-comment-container' + (question.id ? '_' + question.id : '')"
    :title="calculateTitle(question)"
    :style="(question.io === 'outgoing' ? '' : 'color: red;' )"
    :is-visible="!question.id"
  >
    <b-row
      v-if="!isNew"
      class="mb-2"
    >
      <b-col
        lg="4"
        md="6"
      >
        <label>Customs query</label>
        <div><strong>{{ question ? question.comment : '' }}</strong></div>
      </b-col>
      <b-col
        lg="2"
        md="6"
      >
        <label>Date</label>
        <div><strong v-if="question">{{ question.created_at | formatDate }}</strong></div>
      </b-col>
      <b-col
        lg="2"
        md="6"
      >
        <label>User</label>
        <div>
          <strong v-if="question && question.io === 'incoming'">Customs</strong>
          <strong v-else-if="question && question.user">{{ question.user.name }}</strong>
        </div>
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <div v-if="question && question.files && question.files.length > 0">
          <label>Attachments</label><br>
          <div
            v-for="(file, idx) in question.files"
            :key="idx"
            style="display: inline;margin-right: 10px;"
          >
            <b-link :href="`${fileBaseUrl}/files/${file.id}`">
              {{ file.name }}
            </b-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- only show for new outgoing ones -->
    <validation-observer ref="communicationValidation">
      <b-row v-if="isNew">
        <b-col
          :lg="canAddDocuments() ? 6 : 12"
          md="6"
        >
          <validation-provider
            #default="{ errors }"
            name="Comment"
            rules="required"
          >
            <label v-if="canAddDocuments()">Comment on documents</label>
            <label v-else>Comment</label>
            <b-form-textarea
              v-model="answer.comment"
              :state="errors.length > 0 ? false:null"
              rows="9"
            />
          </validation-provider>
        </b-col>
        <b-col
          v-if="canAddDocuments()"
          lg="6"
          md="6"
        >
          <label>
            Documents
            <span
              v-if="checkFile"
              class="text-danger"
            >
              Allowed file types: pdf, jpg, png, tif
            </span>
          </label>
          <b-form-file
            v-if="!upload.isUploading"
            id="upload-btn"
            :ref="`fileHandle-${declaration.id}`"
            v-model="uploadFile"
            :accept="checkFile ? 'application/pdf, image/jpeg, image/png, image/tiff' : ''"
            placeholder="Select a file"
            drop-placeholder="Drop file here..."
            :multiple="true"
            class="b-custom-control-xl"
            @input="submitFile()"
          />
          <b-progress
            v-if="upload.isUploading"
            ref="fileProgress"
            :value="upload.uploadProgress"
            max="100"
          />
        </b-col>
      </b-row>
      <b-row v-if="isNew">
        <b-col
          lg="6"
          md="6"
        />
        <b-col
          v-if="canAddDocuments()"
          lg="6"
          md="6"
        >
          <label>Documents</label><br>
          <div
            v-for="(file, idx) in question.files"
            :key="idx"
            style="display: inline;margin-right: 10px;"
          >
            <b-link :href="`${fileBaseUrl}/files/${file.id}`">
              {{ file.name }}
            </b-link>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="isNew"
        class="pt-1"
      >
        <b-col class="text-right">
          <b-button
            variant="primary"
            @click="validationForm()"
          >
            Add Response
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </app-collapse-item>
</template>

<script>
import {
  BRow,
  BCol,
  BProgress,
  BFormFile,
  BLink,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BRow,
    BCol,
    BLink,
    BProgress,
    BFormFile,
    AppCollapseItem,
    BFormTextarea,
  },
  props: ['question', 'shipmentId', 'declaration', 'event', 'isNew', 'parcelId'],
  data() {
    return {
      uploadFile: null,
      answer: {
        comment: '',
        customs_attachments: [],
      },
      upload: {
        isUploading: false,
        uploadProgress: 0,
      },
      fileBaseUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/${this.declaration.id}`,
    }
  },
  methods: {
    canAddDocuments() {
      return this.declaration.customs_country !== 'SK'
    },
    validationForm() {
      this.$refs.communicationValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.submitAnswer()
      })
    },
    submitAnswer() {
      if (this.answer.comment) {
        this.$http.post(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/${this.declaration.id}/communications`, this.answer).then(() => {
          this.event.$emit('update-parcel-items', this.parcelId)
        })
      }
    },
    checkFile() {
      return this.$activeCompany().data.company.country === 'FI'
    },
    isFileValid(file) {
      if (!this.checkFile) {
        return true
      }
      const extension = file.name.split('.').pop()
      if (!['jpg', 'png', 'tif', 'pdf'].includes(extension)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Allowed file types are pdf, jpg, png and tif',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        return false
      }
      if (file.size > 5000000) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select a file smaller than 5MB',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    submitFile() {
      let isFileValid = true
      const formData = new FormData()
      formData.append('comment', this.answer.comment)
      this.uploadFile.forEach((file, i) => {
        isFileValid = this.isFileValid(file)
        if (!isFileValid) {
          return
        }
        formData.append(`files[${i}]`, file)
      })
      if (!isFileValid) {
        return
      }
      this.upload.isUploading = true
      this.upload.uploadProgress = 0
      this.$http.post(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/${this.declaration.id}/files`,
        formData,
        {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.upload.uploadProgress = percentCompleted

            return percentCompleted
          }.bind(this),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.upload.isUploading = false
          if (response.data.customsAttachments) {
            this.answer.customs_attachments = response.data.customsAttachments
          }
          this.question.files = response.data.files
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File successfully uploaded',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.upload.isUploading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to upload file',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    calculateTitle(comm) {
      const commentText = this.truncate(comm.comment, 100)
      if (this.isNew) {
        return 'New response to customs'
      }
      if (comm.io === 'incoming') {
        return `Customs: ${commentText}`
      }
      return `Your response: ${commentText}`
    },
    truncate(text, length, clamp) {
      clamp = clamp || '...'
      const node = document.createElement('div')
      node.innerHTML = text
      const content = node.textContent
      return content.length > length ? content.slice(0, length) + clamp : content
    },
  },
}
</script>
